import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"

import './contacts.component.less';

import Glotov from "../../assets/img/contacts/glotov.jpg"
import Trubitsyn from "../../assets/img/contacts/trubitsyn.jpg"
import Kliavlin from "../../assets/img/contacts/kliavlin.jpg"
import Getman from "../../assets/img/contacts/getman.png"
import Service from "../../assets/img/contacts/service.png"
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const ContactsComponent = () => {
    const {i18n, t} = useTranslation()
    const {originalPath} = useI18next()

    return (
        <div>
            <SEO description={t("metaDescriptionContacts")} lang={i18n.language}
                 title={t("titleContacts")}
                 link={"https://cityair.ru/" + i18n.language + originalPath}/>
            <div
                className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <div
                                className="color_purple contact_main_block__title">{t("contacts")}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5  flex_box_col flex_box_align_ipad">
                            <div
                                className="contact_main__title">{t("contactUs")}</div>
                            <div
                                className="contact_main__description color_black_light">{t("ifYouWishCreating")}</div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1"/>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5  flex_box_col flex_box_align_ipad">
                            <div className="color_purple contact_main__mail">
                                <a className="contact_main__a_mail"
                                   href="mailto:info@cityair.ru">{t("mailInfoCityair")}</a>
                            </div>
                            <div
                                className="color_purple contact_main__mail contact_main__mail-too">
                                <a className="contact_main__a_mail"
                                   href="mailto:sales@cityair.ru">{t("mailInfoSales")}</a>
                            </div>
                            <div className="color_purple contact_main__tell"><a
                                href={'tel:' + t("phoneNumber").replace(/\s/g, '')}>{t("phoneNumber")}</a>
                            </div>
                            <div
                                className="contact_main__sub_title">{t("moscow")}</div>
                            <div
                                className="color_gray contact_main__sub_description">{t("skolkovoInnovationCenter")}</div>
                            <div
                                className="contact_main__sub_title">{t("novosibirsk")}</div>
                            <div className="color_gray contact_main__sub_description contact_main__sub_description-last">{t("addressNovosibirsk")}</div>
                            <div className="color_gray contact_main__sub_description contact_main__sub_description-last">{t("addressNovosibirsk2")}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="container contact_block_1__container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div
                                className="contact_block_1__title">{t("youMightAlso")}</div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1"/>
                        <div
                            className="col-12 col-sm-11 col-md-11 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <ul className="color_black_light contact_block_1__description_1">
                                <li className="content_container__list-li">{t("dataAccess")}</li>
                                <li className="content_container__list-li">{t("jointResearchIn")}</li>
                            </ul>
                            <div
                                className="contact_block_1__description_2 color_black_light">{t("weWillBe")}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container wrapper_container-gray">
                <div className="container contact_block_2__container">
                    <div className="row">
                        <div
                            className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2">
                            <img alt="" src={Glotov} className="img_photo"/>
                        </div>
                        <div
                            className="col-9 col-sm-9 col-md-9 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 contact_block_2__text">
                            {i18n.language === 'en' && <div
                                className="color_black_light contact_block_2__questions contact_block_2__questions-support-en"/>}
                            {i18n.language === 'ru' && <div
                                className="color_black_light contact_block_2__questions">{t("offerAPartnership")}</div>}
                            <div
                                className="contact_block_2__name">{t("pavelGlotov")}</div>
                            <div
                                className="color_gray contact_block_2__position">{t("CEO")}</div>
                            <div className="color_purple contact_block_2__mail">
                                <a className="contact_main__a_mail"
                                   href="mailto:gp@cityair.ru">{t("mailGlotov")}</a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-3 col-md-3 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1 contact_block_2__1600_display_none"/>
                        <div
                            className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2">
                            <img alt="" src={Trubitsyn} className="img_photo"/>
                        </div>
                        <div
                            className="col-9 col-sm-9 col-md-9 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 contact_block_2__text">
                            {i18n.language === 'en' && <div
                                className="color_black_light contact_block_2__questions contact_block_2__questions-support-en"/>}
                            {i18n.language === 'ru' && <div
                                className="color_black_light contact_block_2__questions">{t("offerAPartnership2")}</div>}
                            <div
                                className="contact_block_2__name">{t("dmitriyTrubitsyn")}</div>
                            <div
                                className="color_gray contact_block_2__position">{t("directorOfDevelopment")}</div>
                            <div className="color_purple contact_block_2__mail">
                                <a className="contact_main__a_mail"
                                   href="mailto:td@cityair.ru">{t("mailTrubitsyn")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2">
                            <img alt="" src={Kliavlin} className="img_photo"/>
                        </div>
                        <div
                            className="col-9 col-sm-9 col-md-9 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 contact_block_2__text">
                            {i18n.language === 'en' && <div
                                className="color_black_light contact_block_2__questions contact_block_2__questions-support-en"/>}
                            {i18n.language === 'ru' && <div
                                className="color_black_light contact_block_2__questions">{t("commerceAndPartnerships")}</div>}
                            <div
                                className="contact_block_2__name">{t("andreyKlyavlin")}</div>
                            <div
                                className="color_gray contact_block_2__position">{t("CCO")}</div>
                            <div className="color_purple contact_block_2__mail">
                                <a className="contact_main__a_mail"
                                   href="mailto:klyavlin-aa@cityair.ru">{t("mailKlyavlin")}</a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-3 col-md-3 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1 contact_block_2__1600_display_none"/>
                        <div
                            className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2">
                            <img alt="" src={Getman} className="img_photo"/>
                        </div>
                        <div
                            className="col-9 col-sm-9 col-md-9 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 contact_block_2__text">
                            {i18n.language === 'en' && <div
                                className="color_black_light contact_block_2__questions contact_block_2__questions-support-en"/>}
                            {i18n.language === 'ru' && <div
                                className="color_black_light contact_block_2__questions">{t("mediaComments")}</div>}
                            <div
                                className="contact_block_2__name">{t("anastasiaGetman")}</div>
                            <div
                                className="color_gray contact_block_2__position">{t("marketingDirector")}</div>
                            <div
                                className="color_purple contact_block_2__mail contact_block_2__mail-getman">
                                <a className="contact_main__a_mail"
                                   href="mailto:pressa@cityair.ru">{t("mailGetman")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2">
                            <img alt="" src={Service}
                                 className="img_photo img_photo_servise"/>
                        </div>
                        <div
                            className="col-9 col-sm-9 col-md-9 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 contact_block_2__text">
                            <div
                                className="color_black_light contact_block_2__questions contact_block_2__questions-support"/>
                            <div
                                className="contact_block_2__name">{t("support")}</div>
                            <div className="color_gray"/>
                            <div
                                className="color_purple contact_block_2__mail contact_block_2__mail-end">
                                <a className="contact_main__a_mail"
                                   href="mailto:service@cityair.ru">{t("mailService")}</a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-3 col-md-3 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1 contact_block_2__1600_display_none"/>
                        <div
                            className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2">
                            <img alt="" src={Service}
                                 className="img_photo img_photo_servise"/>
                        </div>
                        <div
                            className="col-9 col-sm-9 col-md-9 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 contact_block_2__text">
                            <div
                                className="color_black_light contact_block_2__questions contact_block_2__questions-support"/>
                            <div
                                className="contact_block_2__name">{t("salesDepartment")}</div>
                            <div className="color_gray"/>
                            <div
                                className="color_purple contact_block_2__mail contact_block_2__mail-end">
                                <a className="contact_main__a_mail"
                                   href="mailto:sales@cityair.ru">{t("mailInfoSales")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ContactsComponent.propTypes = {}

ContactsComponent.defaultProps = {}

export default ContactsComponent
